@font-face {
    font-family: 'Inter-Regular';
    src: url('../fonts/Inter-Regular.ttf');
}
@font-face {
    font-family: 'Inter-Medium';
    src: url('../fonts/Inter-Medium.ttf');
}
@font-face {
    font-family: 'Inter-SemiBold';
    src: url('../fonts/Inter-SemiBold.ttf');
}
@font-face {
    font-family: 'Inter-Bold';
    src: url('../fonts/Inter-Bold.ttf');
}
@font-face {
    font-family: 'DMSans-Regular';
    src: url('../fonts/DMSans-Regular.ttf');
}
@font-face {
    font-family: 'DMSans-Medium';
    src: url('../fonts/DMSans-Medium.ttf');
}
@font-face {
    font-family: 'DMSans-Bold';
    src: url('../fonts/DMSans-Bold.ttf');
}


